@import '../../index.scss';

.bg-grey {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $secondary-9;
    height: 100vh;

    a {
        margin: 1rem;
        color: $secondary-1;
    }
}
