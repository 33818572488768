.churchInfo {
    margin: 1rem 1rem;

    h4 {
        font-weight: bold;
        text-decoration: underline;
    }

    p {
        margin-bottom: 1rem;
    }
}