@import '../../index.scss';

#home {
    background-color: $secondary-9;
    height: 100%;
    min-height: 100vh;
    
    .home-content {
        padding: 1rem;

        .modal {
            padding: 1rem;
        }
    }
}


.church-container {
    width: 250px;
    padding: 1rem;

    ul {
        padding-top: 0.5rem;
        border-top: 1px solid $secondary-6;
        padding-left: 1rem;
    }
}