@import '../../index.scss';

.settings-container {
    background-color: $secondary-10;
    display: grid;
    width: 100vw;
    min-height: 100vh;
    padding: 1rem;
}

.file-cta {
    margin: 1rem 4rem;
}

#pictureLabel {
    margin: 1rem 5rem;
}