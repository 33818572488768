.directory-grid {
    display: grid;
    width: 90%;
    padding: 1rem;
    grid-template-columns: repeat( auto-fit, minmax(420px, 1fr) );
    grid-gap: 10px;
    margin: 0 auto;

    @media (max-width: 512px) {
        grid-template-columns: repeat( auto-fit, minmax(220px, 1fr) );
    }

    @media (min-width: 768px) and (max-width: 850px) {
        grid-template-columns: repeat( auto-fit, minmax(320px, 1fr) );
    }
}

.user-card {
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    border: 1px solid #CED0D4;
    display: flex;
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    .avatar {
        margin: 1rem 0 0 0;
    }

    .info {
        max-width: 75%;
        margin: 1rem;
    }
    
    .info .name {
        font-size: 20px;
        color: darkblue;
        margin-bottom: 0.5rem;
    }
    
    .info .email {
        font-size: 15px;
        color: grey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .info .phone {
        font-size: 15px;
        color: grey;
    }

    .remove {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.user-card img {
    margin-right: 10px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.user-card:hover {
    animation: bob-up 0.2s ease-in-out;
    animation-fill-mode: forwards;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

@keyframes bob-up {
    0% { transform: translateY(0px) }
    100% { transform: translateY(-5px) }
}



.fa {
    margin-right: 5px;
}