@import '../../index.scss';

.profileContainer {
    background-color: $secondary-9;
    height: 100%;
    min-height: 100vh;
}

.profileBanner {
    min-height: 40vh;
    // background-color: #65D6AD;
    background: linear-gradient(132deg, #3DBF90, #fffefe);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
}

.avatarInfo {
    margin: 1rem;
    color: white;
    .userName {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}

.userInfo {
    width: fit-content;
    margin-top: 1rem;
}

.card-grid {
    display: grid;
    width: 90%;
    padding: 1rem;
    grid-template-columns: repeat( auto-fit, minmax(420px, 1fr) );
    grid-gap: 10px;
    margin: 0 auto;

    .box {
        height: 300px;
        overflow-y: scroll;
    }
}