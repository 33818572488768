@import "../../index.scss";


$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 48%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);


#login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: $secondary-9;

	.login-card {
		background: #fff;
		width: 24rem;
		box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.11);
        border: 1px solid $secondary-8;

		.card-title {
			background-color: $primary-5;
			padding: 2rem;

			h1 {
				color: #fff;
				text-align: center;
				font-size: 1.2rem;
			}
		}

		.content {
			padding: 3rem 2.5rem 3rem;

			p {
				text-align: center;
			}
		}

		#email, #password {
			display: block;
			width: 100%;
			font-size: 1rem;
			margin-bottom: 1.75rem;
			padding: 0.25rem 0;
			border: none;
			border-bottom: 1px solid $secondary-7;
			transition: all .5s;

			&:hover {
				border-color: $secondary-4;
			}

			&:active, &:focus {
				border-color: $secondary-4;
			}
		}

		.checkbox {
			color: $grey-light;
			font-size: 0.8rem;

			span {
				margin-left: 0.5rem;
			}
		}

		a {
			font-size: 0.8rem;
		}

		.options {
			color: $grey-light;
			margin-bottom: 1.5rem;
		}

		button {
			cursor: pointer;
			font-size: 1.2rem;
			color: $primary-10;
			border-radius: 4rem;
			display: block;
			width: 100%;
			background: $primary-5;
			border: 2px solid $primary-6;
			transition: color .5s, border-color .5s;
			
			&:hover, &:focus {
				animation: bob-up 0.2s ease-in-out;
				animation-fill-mode: forwards;
				box-shadow: 0 100px 100px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
			}
		}
	}

	a {
		margin: 1rem;
        color: $secondary-1;
	}
}

@keyframes bob-up {
	0% { transform: translateY(0px); }
	100% { transform: translateY(-5px); }
}
  

label {
	cursor: pointer;
}

.regular-checkbox {
	display: none;
}

.regular-checkbox + label {
	background-color: #fafafa;
	border: 1px solid $grey-lighter;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05);
	padding: 7px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.regular-checkbox:checked + label {
	background-color: #e9ecee;
}

.regular-checkbox:checked + label:after {
	content: '\2714';
	font-size: 11px;
	position: absolute;
	top: 0;
	left: 3px;
	color: $grey-light;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}