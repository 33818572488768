@import '../../index.scss';

.bg-grey {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $secondary-9;
    height: 100vh;

    a {
        margin: 1rem;
        color: $secondary-1;
    }
}

.card {
    width: 24rem;
    border: 1px solid $secondary-8;

    .card-header {
        background-color: $primary-5;
        padding: 1.3rem;
        text-align: center;

        .card-header-title {
            display: block;
            color: white;
            font-weight: normal;
            text-align: center;
            font-size: 1.2rem;
        }
    }

    .content {
        padding: 3rem 2.5rem 1.5rem;

        p {
            text-align: center;
        }
    }

    input {
        display: block;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 1.75rem;
        padding: 0.25rem 0;
        border: none;
        border-bottom: 1px solid $secondary-7;
        transition: all .5s;

        &:hover {
            border-color: $secondary-4;
        }

        &:active, &:focus {
            border-color: $secondary-4;
        }
    }

    button {
        cursor: pointer;
        font-size: 1.2rem;
        color: $primary-6;
        border-radius: 4rem;
        display: block;
        width: 100%;
        background: transparent;
        border: 2px solid $primary-6;
        transition: color .5s, border-color .5s;
        
        &:hover, &:focus {
            color: $primary-10;
            background: $primary-5;
        }

        &:active {
            transform: translateY(1px);
        }
    }
}

